<template>
  <v-card flat>
    <v-card-text>
      <v-list flat tile dense>
        <v-list-item-group color="primary">
          <v-subheader v-text="$t('DashooardSidebar.dashboard')"></v-subheader>

          <v-list-item @click="() => $router.push(`/entities/${entidadId}/account/orders`).catch(() => {})">
            <v-list-item-icon>
              <v-icon>mdi-shopping-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t('DashooardSidebar.orders')"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              {{ statistics.cantidadOc || 0 }}
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="() => $router.push(`/entities/${entidadId}/account/wishlist`).catch(() => {})">
            <v-list-item-icon>
              <v-icon>mdi-heart-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t('DashooardSidebar.wishlist')"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              {{ statistics.cantidadFavoritos || 0 }}
            </v-list-item-action>
          </v-list-item>

          <v-subheader v-text="$t('DashooardSidebar.account')"></v-subheader>

          <v-list-item @click="() => $router.push(`/entities/${entidadId}/account`).catch(() => {})">
            <v-list-item-icon>
              <v-icon>mdi-account-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t('DashooardSidebar.profile')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="() => $router.push(`/entities/${entidadId}/account/addresses`).catch(() => {})">
            <v-list-item-icon>
              <v-icon>mdi-map-marker-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t('DashooardSidebar.addresses')"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              {{ statistics.cantidadDirecciones || 0 }}
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="() => $router.push(`/entities/${entidadId}/account/contacts`).catch(() => {})">
            <v-list-item-icon>
              <v-icon>mdi-card-account-phone-outline</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t('DashooardSidebar.contacts')"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              {{ statistics.cantidadContactos || 0 }}
            </v-list-item-action>
          </v-list-item>

          <v-list-item @click="() => logout()">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="$t('DashooardSidebar.logout')"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  computed: {
    entidadId(){
      return this.$route.params.entidadId;
    }
  },
  data(){
    return {
      statistics: {},
    }
  },
  mounted() {
    this.fetchStatistics();
  },
  methods: {
    fetchStatistics(){

      // SE OBTIENE EL ENTIDAD_ID A PARTIR DE LOS PARAMETROS DE RUTA
      const entidadId = this.$route.params.entidadId;

      // SE ENVIA LA PETICION AL ENDPOINT PRIVADO DE ESTADISTICAS
      axios.get(`api/v1/entities/${entidadId}/statistics`)
        .then((res) => {

            // SE OBTIENEN LAS ESTADISTICAS DE MANERA SEGURA
            const statistics = _.get(res, 'data.data', {});

            // SE ESTABLECEN LAS ESTADISTICAS EN VARIABLE REACTIVA
            this.statistics = statistics;

        })
        .catch(() => {

          // SE LIMPIAN LAS ESTADISTICAS DE LA VARIABLE REACTIVA
          this.statistics = {};
        });
    },
    logout() {
      this.$store
        .dispatch("authentication/logout", this.user)
        .then(() => {
          this.$router.push('/').catch(() => {});
        })
        .catch((err) => {
          this.$console.error("[ERR]", err);
        });
    },
  },
};
</script>