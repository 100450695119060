<template>
  <v-container class="py-6">
    <n-panels left-panel-icon="mdi-menu">
      
      <template #left>
        <dashboard-sidebar />
      </template>

      <template #center>
        <router-view />
      </template>

    </n-panels>
  </v-container>
</template>

<script>
import NPanels from '../../components/newgen/NPanels.vue';
import DashboardSidebar from "./DashboardSidebar"

export default {
  components: {
    NPanels,
    DashboardSidebar,
  },
  data() {
    return {
      isSidebar: false,
    };
  },
}
</script>